'use client';

import { faExternalLink, faUserCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { User } from 'protos/model';
import React, { useEffect, useState } from 'react';

import { UserProfile } from '@/modules/top-navigation/UserProfile';

interface TopNavigationProps {
	user: User | undefined;
}

export default function TopNavigation({ user }: TopNavigationProps) {
	const pathname = usePathname();
	const [baseURL, setBaseURL] = useState('');

	useEffect(() => {
		setBaseURL(window.location.href);
	}, []);

	const currentPath = pathname && pathname.split('/');
	const applyWhiteBg =
		(currentPath && currentPath[2] && currentPath[1] === 'players') || (currentPath && currentPath[2] && currentPath[1] === 'results');

	const topNavigationClasses = clsx('hidden text-white sm:block', {
		'bg-experiment': !applyWhiteBg,
		'bg-gray-100': applyWhiteBg
	});

	const pickleballCentralClasses = clsx('inline-flex items-center gap-2 pr-4 text-sm font-medium underline-offset-4 hover:underline', {
		'text-gray-700 decoration-gray-700': applyWhiteBg,
		'decoration-white': !applyWhiteBg
	});

	const loginButtonClasses = clsx(
		'mr-2 flex items-center gap-2 !bg-transparent !py-1.5 text-sm font-medium !leading-none text-gray-700 hover:!bg-transparent',
		{
			'text-white hover:!text-white/80': !applyWhiteBg,
			'text-experiment hover:!text-experiment/80': applyWhiteBg
		}
	);

	const signUpClasses = clsx('rounded !py-1.5 px-2 text-sm font-medium !leading-none', {
		'!bg-white !text-experiment hover:!text-experiment': !applyWhiteBg,
		'!bg-experiment hover:!text-white/80': applyWhiteBg
	});

	const isLocalHost = process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME?.startsWith('localhost');

	const pickleballHref = isLocalHost ? '' : `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}`;

	return (
		<div className={topNavigationClasses} id="pb-topnav">
			<ul className="mx-auto flex h-10 max-w-[1200px] items-center justify-between px-4">
				<li className="divide-x divide-gray-400">
					<Link href="https://pickleballcentral.com" target="_blank" className={pickleballCentralClasses}>
						<span className="inline-flex">Shop Pickleball Central</span>
						<FontAwesomeIcon icon={faExternalLink} size="sm" />
					</Link>
				</li>
				<li className="divide-x divide-gray-400">
					<Link href={`${pickleballHref}/watch-now`} className={pickleballCentralClasses}>
						<span className="inline-flex">Where to Watch Pickleball</span>
					</Link>
				</li>
				<li className="flex items-center gap-2">
					{user ? (
						<UserProfile user={user} isWhiteBackground={applyWhiteBg ? applyWhiteBg : false} />
					) : (
						<>
							<a
								href={`${process.env.NEXT_PUBLIC_SSO_ENDPOINT}/v3/signin?flow=SelfService&continue=${encodeURIComponent(baseURL)}`}
								className={loginButtonClasses}
								rel="nofollow"
							>
								<FontAwesomeIcon icon={faUserCircle} size="sm" className="text-base" />
								Log In
							</a>
							<a
								href={`${process.env.NEXT_PUBLIC_SSO_ENDPOINT}/v3/signup?continue=${encodeURIComponent(baseURL)}`}
								className={signUpClasses}
								rel="nofollow"
							>
								Sign Up
							</a>
						</>
					)}
				</li>
			</ul>
		</div>
	);
}
