import { BLOG_LABEL } from '@/utils/helpers/constants';

const isLocalHost = process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME?.startsWith('localhost');

interface NavItem {
	id: string;
	children: string;
	target?: string;
	href: string;
}

export const NAVIGATION_ITEMS: NavItem[] = [
	{
		id: '1',
		children: 'News',
		href: isLocalHost ? '/news/all' : `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/news/all`
	},
	{
		id: '2',
		children: 'Tournaments',
		href: isLocalHost ? '/tournaments' : `${process.env.NEXT_PUBLIC_PT_URL}`
	},
	{
		id: '3',
		children: 'Results',
		href: isLocalHost ? '/results' : `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/results`
	},
	{
		id: '4',
		children: 'Leagues',
		href: isLocalHost ? '/leagues' : `${process.env.NEXT_PUBLIC_PL_URL}`
	},
	{
		id: '5',
		children: 'Clubs',
		href: isLocalHost ? '/clubs' : `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/clubs`
	},
	{
		id: '6',
		children: 'Players',
		href: isLocalHost ? '/players' : `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/players`
	},
	{
		id: '7',
		children: 'Rankings',
		href: isLocalHost ? '/rankings' : `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/rankings`
	},
	{
		id: '8',
		children: 'Tickets',
		target: '_blank',
		href: 'https://www.tixr.com/groups/ppa'
	},
	{
		id: '9',
		children: BLOG_LABEL,
		href: isLocalHost ? '/news/all' : `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/news/all`
	}
];
